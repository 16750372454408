import React, { Fragment } from "react"
import { Carousel, Image } from 'react-bootstrap';
const NextIcon = () => {
    return (
        <Image src={require('./../Assets/Image/next-icon.png')} className="next-icon" />
    )
}
const PrevIcon = () => {
    return (
        <Image src={require('./../Assets/Image/prev-icon.png')} className="prev-icon" />
    )
}
const Carrousel = () => {
    return (
        <Fragment>
            <Carousel fade indicators={false} interval={10000} nextIcon={<NextIcon />} prevIcon={<PrevIcon />}>
                <Carousel.Item className="custom-carrousel image1">
                    {/* <Image className="custom-carrousel-header" src={require('./../Assets/Image/carrousel-header.png')} /> */}
                </Carousel.Item>
                 <Carousel.Item className="custom-carrousel image2">
                    {/*<Image className="custom-carrousel-header" src={require('./../Assets/Image/carrousel-header.png')} />*/}
                </Carousel.Item>
                {/*<Carousel.Item className="custom-carrousel image3">
                    <Image className="custom-carrousel-header" src={require('./../Assets/Image/carrousel-header.png')} />
                </Carousel.Item> */}
            </Carousel>
        </Fragment>
    )
}
export default Carrousel;