import React from "react"
import Home from './Route/Home'
//import ReactGA from 'react-ga4';

//ReactGA.initialize('G-VKTRX2RDMV');
const App = () => {
  return (
        <Home />
  )
}
export default App;