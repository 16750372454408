import React from "react"
import { Col, Container, Row } from "react-bootstrap";
import GiveInfoFormMobile from './FormMobile'
const ContactFormMobile = () => {
    return (
        <Container fluid>
            <Row>
                <Col>
                    <GiveInfoFormMobile />
                </Col>
            </Row>
            <Row>
                <Col className="text-center m-5">
                    <img src={require('./../Assets/Image/Call.png')} alt="Hilal Hill Büyükçekmece" />
                </Col>
            </Row>
        </Container>
    )
}
export default ContactFormMobile;