import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import Carrousel from '../Components/Carrousel'
import ContactForm from '../Components/ContactForm'
import { BrandLogo } from '../Components/Utils'
import ContactFormMobile from "../Components/ContactMobile";
const Home = () => {
    return (
        <Container fluid>
            {/* Mobile Header Start */}
            <Row className="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none">
                <Col className="mobile-header">
                    <BrandLogo logoHeight={80} />
                </Col>
            </Row>
            {/* Mobile Header End */}
            <Row>
                <Col sm={12} md={12} lg={8} xl={8} xxl={8} className="no-padding">
                    <Carrousel />
                    <div className="d-xs-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block maps">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12041.534703247018!2d28.6191084!3d41.0168612!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa5bff7e7917d%3A0xd43dc072aca33df5!2zSGlsYWwgSGlsbCBCw7x5w7xrw6dla21lY2UgU2F0xLHFnyBPZmlzaQ!5e0!3m2!1str!2str!4v1698322078961!5m2!1str!2str"
                            width='100%'
                            style={{ "height": '100vh' }}
                            loading="lazy"
                            title="Hilal Hill Büyükçekmece Harita" 
                            />
                    </div>
                </Col>
                <Col sm={12} md={12} lg={4} xl={4} xxl={4} className="d-xs-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block no-padding maps">
                    <ContactForm />
                </Col>
                <Col sm={12} md={12} lg={4} xl={4} xxl={4} className="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none no-padding">
                    <ContactFormMobile />
                </Col>
            </Row>
            <Row className="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none no-padding">
                <Col>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12041.534703247018!2d28.6191084!3d41.0168612!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa5bff7e7917d%3A0xd43dc072aca33df5!2zSGlsYWwgSGlsbCBCw7x5w7xrw6dla21lY2UgU2F0xLHFnyBPZmlzaQ!5e0!3m2!1str!2str!4v1698322078961!5m2!1str!2str"
                        width='100%'
                        style={{ "height": '100vh' }}
                        loading="lazy"
                        title="Hilal Hill Büyükçekmece Harita" />
                </Col>
            </Row>
        </Container>

    )
}
export default Home;