import React, { useState, useRef } from "react"
import { Form, Button, Modal } from "react-bootstrap";
import emailjs from '@emailjs/browser';
const ContanctForm = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const queryParameters = new URLSearchParams(window.location.search)
    const utmsource = queryParameters.get("utm_source")
    const utmmedium = queryParameters.get("utm_medium")
    const utmcampaign = queryParameters.get("utm_campaign")
    const utmcontent = queryParameters.get("utm_content")
    const [ticket, setTicket] = useState(false)
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_9t9po0l', 'form-hilal-hill', form.current, 'JIzpYPX1XvFCKLmbz')
            .then((result) => {
                setTicket(true)
                window.location.href = '/tesekkurler.html';
            }, (error) => {
            });
        // emailjs.sendForm('service_a0yxzv4', 'hilal-hill', form.current, 'SilpKTuzPi28Q3bYU')
        //     .then((result) => {
        //         setTicket(true)
        //     }, (error) => {
        //     });
    };
    return (
        <div>
            {ticket === false ? (
                <>
                    <h1 className="contact-contents-h1 text-center mt-5">Bilgi Talep Formu</h1>
                    <h4 className="contact-contents-h4 text-center">Formu doldurun, size ulaşalım.</h4>
                    <h3 class="contact-contents-h3">Information Request Form</h3>
                    <h4 class="contact-contents-h4">Fill in the form, we will contact you</h4>
                    <Form ref={form} onSubmit={sendEmail}>
                        <Form.Control name="utmsource" type="hidden" value={utmsource}/>
                        <Form.Control name="utmmedium" type="hidden" value={utmmedium}/>
                        <Form.Control name="utmcampaign" type="hidden" value={utmcampaign}/>
                        <Form.Control name="utmcontent" type="hidden" value={utmcontent}/>
                        <Form.Control className="form-input" required name="firstname" type="text" placeholder="Adınız / Your name" />
                        <Form.Control className="form-input" required name="lastname" type="text" placeholder="Soyadınız / Your surname" />
                        <Form.Control className="form-input" required name="phone" type="phone" placeholder="Telefon numaranız / Your phone number" />
                        <Form.Control className="form-input" required name="email" type="email" placeholder="E-posta adresiniz / Your e-mail address" />
                        <Form.Check className="form-checkbox" required name="kvkk" type="checkbox" label={<span className="checkbox-link" onClick={handleShow}>KVKK aydınlatma metnini okudum. / I have read the KVKK clarification text</span>} />
                        <Form.Check className="mb-3" type="checkbox" name="approval" label="Kampanyalardan haberdar olmak için tarafıma elektronik ileti gönderilmesini kabul ederim. / I agree to receive electronic messages to be informed about campaigns" />
                        <Button type="submit" className="green-radius-button">Gönder / Send</Button>
                    </Form>
                </>
            ) : (<>
                {/* <h1 className="contact-contents-h1 mb-5 text-center mt-5">Mesajınız gönderildi <br/><span className="text-pink">teşekkür ederiz!</span></h1>
                <h4 className="contact-contents-h4 mb-5 text-center">Paylaştığınız İletişim Bilgileri Üzerinden Temsilcilerimiz En Kısa  Zamanda Sizinle İletişime Geçecek.</h4>
                <Button onClick={() => setTicket(false)} className="green-radius-button">Anasayfaya dön</Button> */}
            </>
            )
            }
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>KİŞİSEL VERİLERİN KORUNMASI AYDINLATMA METNİ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-justify">İşbu Aydınlatma Metni; 6698 Sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) 10. maddesi uyarınca “Veri Sorumlusunun Aydınlatma Yükümlülüğü” kapsamında; internet sitemizdeki form aracılığıyla otomatik yollarla bir veri kayıt sisteminin parçası olmak kaydıyla işlenen/ toplanan kişisel verileriniz hakkında veri sorumlusu sıfatıyla <strong>Büyükçekmece Kentsel Dönüşüm Adi Ortaklığı</strong> (“Şirket”) tarafından aydınlatılmaktasınız.</p>
                    <strong className="text-justify">1. Kişisel Verilerinizin İşlenmesi, Aktarılması Amacı ve Hukuki Sebebi;</strong>
                    <p className="text-justify"><strong>1.1.Adınız, Soyadınız, E-posta adresiniz, telefon numaranız, mesajınız ve nereden duydunuz kısmına yazmış olduğunuz veriler şeklindeki kişisel veriniz,</strong> sizlerle kuracağımız iletişim ve etkileşimler sırasında ve randevu formunu doldurmanız ile edinilecek kişisel verileriniz, KVKK 5/2 uyarınca sözleşmenin ifası ve bir hakkın tesisi için gerekli olması hukuki sebebine dayanılarak işlenmektedir.</p>
                    <p className="text-justify"><strong>1.2.Adınız, Soyadınız, E-posta adresiniz, telefon numaranız, mesajınız ve nereden duydunuz kısmına yazmış olduğunuz veriler şeklindeki kişisel veriniz,</strong> sizlerle kuracağımız iletişim ve etkileşimler sırasında edinilecek kişisel verileriniz, <strong>Elektronik Ticaretin Düzenlenmesi Hakkında Kanun uyarınca onayınız ve KVKK 5. maddesi uyarınca açık rızanız olması halinde;</strong>
                    </p>
                    <ul className="mb-5">
                        <li>Ürün ve hizmetlerimizle ilgili güncel gelişmelerden sizleri haberdar etmek,</li>
                        <li>Size özel kampanya ve reklamlar gösterebilmek,</li>
                        <li>Verdiğimiz hizmetleri size özel kişiselleştirebilmek</li>
                        <li><strong>Kampanyalarımız, ürün ve hizmetlerimiz hakkında promosyon, reklam, indirim, tanıtımlar için SMS, Aranma ve E-posta şeklinde ticari iletiler göndermek amaçlarıyla işlenecektir.</strong></li>
                    </ul>
                    <p className="text-justify"><strong>2.Kişisel Verilerinizin İşlenmesi Amacı ve Hukuki Sebebi;</strong></p>
                    <p className="text-justify">2.1.Yukarıda belirtilen kişisel verileriniz, aynı amaçlarla, sizlere diğer hizmet ve ürünler ile ilgili olarak da gelişmelerden haberdar etmek, size özel kampanya ve reklamlar gösterebilmek, verilen hizmetleri size özel kişiselleştirebilmek, SMS, Aranma ve E-posta şeklinde ticari iletiler göndermek gönderebilmek için <strong>BAHA, FKG, STTA ŞİRKETİ</strong>  başta olmak üzere, iştiraklerimiz, topluluk ve bağlı şirketlerimize aktarılacaktır.</p>
                    <p className="text-justify">2.2.<strong>Kişisel verileriniz,</strong> yukarıda bahsedilen amaç ve faaliyetlerin yerine getirilmesi amacıyla ticari ileti gönderimi, reklam tanıtım işlemleri nedeniyle hizmet alınan veri işleyen, ölçümleme, hedefleme, profilleme desteği veren yurt içi ve yurt dışı kişi ve kuruluşlarla, reklam ve tanıtım ajanslarıyla, hizmet alınan CRM şirketleri ile, sms ve e-posta gönderimi yapılması için hizmet sağlayıcılarla, aramaların yapılması için çağrı merkezleriyle, faaliyetlerimizi gerçekleştirmek için yurt içi ve yurt dışı yazılım şirketleri, sunucu ve hizmet sağlayıcılarla, İleti Yönetim Sistemi A.Ş. ile <strong>açık rızanıza</strong> istinaden paylaşılmaktadır.</p>
                    <p className="text-justify">2.3.Kişisel Verileriniz; Gerektiğinde ilgili mevzuattan doğan bilgi ve belge paylaşımına ilişkin yükümlülüklerimizi yerine getirmek amacıyla KVKK 5/2 maddesi uyarınca Kanunlarda açıkça öngörülmesi ve veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması, hukuki sebeplerine dayalı olarak <strong>“Yetkili Kamu Kurum Ve Kuruluşları”</strong> ile paylaşılabilecektir.</p>
                    <p className="text-justify">2.4.<strong>Kişisel Verileriniz;</strong> Türk Ticaret Kanununda yer alan yükümlülüklerin ve Şirketler Topluluğu konu alan veya Şirketler topluluğunda bulunan şirketler arasında yapılan sözleşmelerde bulunan hükümlerin ifası ve hizmetlerimizin yerine getirilmesi amacıyla KVKK 5/2 maddesi uyarınca veri sorumlusunun meşru menfaati, sözleşmenin kurulması ve ifası için gerekli olması, Kanunlarda öngörülmesi hukuki sebeplerine dayalı olarak <strong>BAHA, FKG, STTA  ŞİRKETİ  “Topluluk Şirketlerimiz’’ “Bağlı Ortaklıklarımız’’ ve “İştiraklerimiz’’</strong> ile paylaşılmaktadır.</p>
                    <p className="text-justify">2.5.<strong>Kişisel Verileriniz,</strong> faaliyetlerimize ilişkin olarak gerekli dış hizmetlerin alınmasına yönelik yaptığımız sözleşmelerin ifası için ve ancak bu amaçla sınırlı olarak KVKK 5/2 maddesi uyarınca veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması, bir sözleşmenin kurulması veya ifası, bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması, hukuki yükümlülüğün yerine getirilmesi hukuki sebeplerine dayalı olarak iş ilişkisi içinde olduğumuz, hizmet aldığımız <strong>“Tedarikçiler”</strong> ve <strong>“İş Ortakları”</strong> ile paylaşılmaktadır. Örneğin; Kişisel verileriniz, sistemlerimizi geliştirmeye ve hizmet kalitemizi arttırmaya yönelik yazılım hizmeti aldığımız <strong>‘’Yazılım Hizmet Sağlayıcı’’</strong> ile, bir hukuki uyuşmazlık halinde hukuk işlerinin takibi ve yürütülmesi amacıyla hizmet aldığımız <strong>Avukatlar</strong> ile; mali yükümlülükler nedeniyle hizmet aldığımız <strong>Mali Müşavirler</strong> ile, denetim durumlarında <strong>Denetçiler</strong> ile işlemin gerçekleşmesi için gerekli amaç ile sınırlı olarak paylaşılmaktadır.</p>
                    <p className="text-justify"><strong>3.Toplama Yöntemi</strong></p>
                    <p className="text-justify">Kişisel verileriniz, sitemizdeki dijital formlar, dijital pazarlama teknoloji araçları, çağrı merkezi, mobil uygulama, elektronik postalar, sosyal medya kanalları ve satış ve pazarlama yetkililerimiz aracılığı ile doğrudan iletişim, hedefleme, profilleme, çevrimiçi davranışsal reklamcılık yöntemleri ile otomatik ve otomatik olmayan yöntemlerlerle işlenmektedir.</p>
                    <p className="text-justify"><strong>4.Haklarınız:</strong></p>
                    <p className="text-justify">Şirket tarafından verilerinizin işlendiği ve Şirket’in verilerinizi veri sorumlusu sıfatı ile işlediği ölçüde kişisel verileriniz bakımından 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun 11. maddesi uyarınca aşağıda bulunan haklara sahipsiniz:</p>
                    <p className="text-justify">“Herhangi bir kişisel verinizin işlenip işlenmediğini öğrenme; işlenme faaliyetlerine ilişkin olarak bilgi talep etme; işlenme amaçlarını öğrenme; yurt içinde veya yurt dışında üçüncü kişilere aktarılmış olması durumunda bu kişileri öğrenme; eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme; işlenmesini gerektiren sebeplerin ortadan kalkması veya Şirket’in söz konusu verileri işleyebilmek için hukuki dayanağı veya meşru menfaatinin bulunmaması halinde kişisel verilerin silinmesini veya yok edilmesini isteme; Şirket’ten, yine Şirket tarafından yetkilendirilen ve kişisel verileri işleyen üçüncü kişilerin bu bölüm kapsamındaki haklarınıza saygı duymasını sağlamasını talep etme; Kişisel verilerin otomatik sistemler vasıtasıyla işlenmesi sonucu ortaya çıkabilecek aleyhte sonuçlara itiraz etme ve; kanuna aykırı bir şekilde işlenmesi sebebiyle zarara uğramanız halinde bu zararın tazmin edilmesini isteme.”</p>
                    <p className="text-justify"><strong>5.Yükümlülükleriniz</strong></p>
                    <p className="text-justify">Üçüncü bir kişinin kişisel verisini İletişim Formu aracılığı ile verdiğiniz takdirde, söz konusu kişinin, 6698 Sayılı Kişisel Verilerin Korunması Kanunu kapsamında aydınlatıldığını, bilgilerinin Şirket’e aktarılmasına rıza vermiş olduğunu ve bu konuda sizi yetkilendirmiş olduğunu kabul etmiş sayılırsınız.ß</p>
                    <p className="text-justify"><strong>6.Veri Sorumlusuna Başvuru</strong></p>
                    <p className="text-justify">Kanunun ilgili kişinin haklarını düzenleyen 11. maddesi kapsamındaki taleplerinizi, “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğe” göre yazılı olarak veya kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ya da tarafınızca daha önce bildirilen ve sistemimizde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle iletebilirsiniz.</p>
                    <p className="text-justify"><strong>7.Veri Sorumlusu Bilgilendirmesi</strong></p>
                    <p className="text-justify"><strong>Unvan: Büyükçekmece Kentsel Dönüşüm Adi Ortaklığı</strong></p>
                    <p className="text-justify"><strong>Mersis no:</strong></p>
                    <p className="text-justify"><strong>E-mail adresi:</strong> info@hilalhill.com.tr</p>
                    <p className="text-justify"><strong>Posta adresi:</strong> Cumhuriyet, Turgut Özal Bv No:131, 34500 Büyükçekmece/İstanbul
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="green-radius-button" onClick={handleClose}>Anladım</Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
export default ContanctForm;
