import React from "react"
import { Col, Container, Row } from "react-bootstrap";
import BrandLogo from "./../Components/Utils";
import GiveInfoForm from './../Components/Form'
const ContactForm = () => {
    return (
        <Container className="d-xs-none d-lg-block contact">
            <Row className="contact-header">
                <Col className="no-padding">
                    <BrandLogo logoHeight={100} />
                </Col>
            </Row>
            <Row className="contact-contents">
                <Col className="no-padding">
                    <GiveInfoForm />
                </Col>
            </Row>
            <Row>
                <Col className="contact-footer">
                    <img src={require('./../Assets/Image/Call.png')} alt="Hilal Hill Büyükçekmece" />
                </Col>
            </Row>
        </Container>
    )
}
export default ContactForm;